import React, { BaseSyntheticEvent, useEffect } from "react";
import { ComponentWidth, KontentRichText } from "../../../types";
import * as styles from "./CatalogSearch.module.css";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";
import { ImageItem } from "@kontent-ai/gatsby-components/dist/image-element";
import CSS from "csstype";
import { useFieldArray, useForm } from "react-hook-form";
import { SectionWrapper } from "../../containers/SectionWrapper";
import { ComponentProps } from "../../../types/index";
import { SearchInput } from "../../elements/SearchInput/SearchInput";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "../../elements/Link/Link";

interface Props extends ComponentProps, ComponentWidth {
  title: string;
  titleColor?: string;
  subhead?: string;
  subheadColor?: string;
  bodyCopy?: KontentRichText;
  bodyCopyColor?: string;
  backgroundColor?: string;
  backgroundImage?: {
    item: ImageItem;
    alt: string;
  };
  backgroundImagePlacement?: "top_left" | "top_right";
}

interface AdvancedFilter {
  boolOperator: null | string;
  fieldValue: string;
  precisionOperation: string;
  searchTerm: string;
}

interface FormValues {
  searchText: string;
}

const defaultValues = {
  searchText: "",
};

export const simpleSearch = (data: FormValues) => {
  if (data.searchText) {
    const query = encodeURIComponent(data.searchText);
    const url = `https://catalog.lindahall.org/discovery/search?vid=01LINDAHALL_INST:LHL&lang=en&offset=0&tab=LibraryCatalog&search_scope=MyInstitution&query=any,contains,${query}&mode=simple`;
    window.open(url, "_blank");
  }
};

export const CatalogSearch: React.FC<Props> = ({
  title,
  titleColor,
  subhead,
  subheadColor,
  bodyCopy,
  bodyCopyColor,
  backgroundColor,
  backgroundImage,
  backgroundImagePlacement,
  width = "full",
  hideOnMobile,
  anchor,
  id,
}) => {
  let sectionClassName = styles.catalogsearch;
  if (width === "contained") {
    sectionClassName += ` ${styles.contained}`;
  }

  const bp = useBreakpoint();

  const titleStyles = titleColor ? { color: titleColor } : {};
  const subheadStyles = subheadColor ? { color: subheadColor } : {};
  const bodyStyles = bodyCopyColor ? { color: bodyCopyColor } : {};
  const sectionStyles: CSS.Properties = {};

  if (backgroundColor) {
    sectionStyles.backgroundColor = backgroundColor;
  }
  if (backgroundImage?.item?.url) {
    sectionStyles.backgroundImage = `url(${backgroundImage.item.url})`;
  }
  if (backgroundImagePlacement === "top_left") {
    sectionStyles.backgroundPosition = "top left";
  }

  const {
    control,
    register,
    watch,
    formState: { isDirty, errors },
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "advancedFilters",
  });

  const watchSearchText = watch("searchText", "");
  const handleFormSubmit = (
    data: FormValues,
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ): void => {
    simpleSearch(data);
  };

  useEffect(() => {
    if (isDirty && errors.searchText) {
      clearErrors("searchText");
    }
  }, [isDirty]);
  const handleFormError = (
    errors: {},
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ): void => {
    console.log("Error", errors, e);
  };

  return (
    <SectionWrapper id={id} anchor={anchor} hideOnMobile={hideOnMobile}>
      <div style={sectionStyles} className={sectionClassName}>
        <form onSubmit={handleSubmit(handleFormSubmit, handleFormError)}>
          <div className={styles.mainFormSection}>
            <div className={styles.content}>
              <h2 style={titleStyles} data-kontent-element-codename="title">
                {title}
              </h2>
              {subhead && (
                <p
                  style={subheadStyles}
                  className={styles.subhead}
                  data-kontent-element-codename="subhead"
                >
                  {subhead}
                </p>
              )}
              {bodyCopy && (
                <div
                  style={bodyStyles}
                  data-kontent-element-codename="simple_rte__text"
                >
                  <CustomRichTextElement content={bodyCopy} />
                </div>
              )}
            </div>
            <div className={styles.formWrap}>
              <div>
                <SearchInput
                  inputProps={register("searchText", {
                    validate: (value) => value !== "" || isDirty,
                  })}
                  placeHolder="SEARCH LIBRARY MATERIAL"
                  disableSubmit={!watchSearchText}
                />
                {errors?.searchText?.type === "validate" && (
                  <div className={styles.errorMessage}>
                    Please enter a search term.
                  </div>
                )}
              </div>
              <div className={styles.advancedWrap}>
                <Link
                  to="https://catalog.lindahall.org/discovery/search?vid=01LINDAHALL_INST:LHL&mode=advanced"
                  target="_blank"
                >
                  Advanced Search
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </SectionWrapper>
  );
};

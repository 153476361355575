import React from "react";
import { graphql } from "gatsby";
import { CatalogSearch } from "./CatalogSearch";
import {
  KontentComponentSettings,
  KontentComponentLayout,
  KontentBrandColor,
  StringValue,
  KontentRichText,
  KontentMultiChoice,
  KontentMediaImage,
} from "../../../types/index";
import { getMultiChoice } from "../../../lib/multichoice";
import { getColor } from "../../../lib/color";
import { formatImages } from "../../../lib/images";

interface Props extends KontentComponentSettings, KontentComponentLayout {
  title: StringValue;
  subhead: StringValue;
  simple_rte__text: KontentRichText;
  background_color: {
    value: KontentBrandColor[];
  };
  body_copy_font_color: {
    value: KontentBrandColor[];
  };
  subhead_font_color: {
    value: KontentBrandColor[];
  };
  title_font_color: {
    value: KontentBrandColor[];
  };
  background_image: { value: KontentMediaImage[] };
  background_image_placement: KontentMultiChoice<"top_left" | "top_right">;
}

const CatalogSearchContainer: React.FC<Props> = ({ ...props }) => {
  const images = formatImages(props.background_image?.value[0]?.elements);

  return (
    <CatalogSearch
      id={props.id}
      anchor={props.component_settings__anchor_name?.value}
      width={getMultiChoice(props.component_layout__component_width)}
      hideOnMobile={
        getMultiChoice(props.component_settings__hide_on_mobile) === "yes"
      }
      title={props.title?.value}
      titleColor={getColor(props.title_font_color)}
      subhead={props.subhead?.value}
      subheadColor={getColor(props.subhead_font_color)}
      bodyCopy={props.simple_rte__text}
      bodyCopyColor={getColor(props.body_copy_font_color)}
      backgroundColor={getColor(props.background_color)}
      backgroundImage={images.largeImage}
      backgroundImagePlacement={
        props.background_image_placement?.value[0]?.codename
      }
    />
  );
};

export default CatalogSearchContainer;

export const fragmentCatalogSearchContainer = graphql`
  fragment CatalogSearch on kontent_item_component___catalog_search {
    elements {
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
      component_layout__component_width {
        value {
          codename
        }
      }
      title {
        value
      }
      subhead {
        value
      }
      simple_rte__text {
        ...kontentSimpleRichText
      }
      background_image {
        value {
          ...kontentMediaImage
        }
      }
      background_image_placement {
        value {
          codename
        }
      }
      background_color {
        ...brandColor
      }
      body_copy_font_color {
        ...brandColor
      }
      subhead_font_color {
        ...brandColor
      }
      title_font_color {
        ...brandColor
      }
    }
  }
`;
